import { has } from "lodash";
import { Action, AnyAction, Reducer, applyMiddleware, compose, legacy_createStore as createReduxStore } from "redux";
import thunkMiddleware from "redux-thunk";

export type ActionHandler<TState, Action extends AnyAction> = (state: TState, action: Action) => TState
// in `[k: string]: Reducer<unknown>`, it should actually be `[k: string]: Reducer<State>`, however, in pages like FormManager, which has FormManagerState as the state type, 
// but also has other states like ResponseState and ApprovalWizardState, it is not possible to have a single state type for all the states, so we use `unknown` as the state type
export const createStore = <State>(reducers: { [k: string]: Reducer<unknown> }, initialState: State = {} as State, instanceName: string = undefined) => {

    const keyValueDispatchReducer = (state: State = initialState, action: Action<string>): State => {

        if (has(reducers, action.type)) {

            return reducers[action.type](state, action) as State;
        }

        return state;
    };


    if (window.__REDUX_DEVTOOLS_EXTENSION__) {
        const store = createReduxStore(
            keyValueDispatchReducer,
            compose(applyMiddleware(thunkMiddleware), window.__REDUX_DEVTOOLS_EXTENSION__({
                name: instanceName || "Store"
            }))
        );
        return store;
    }
    else {
        const store = createReduxStore(
            keyValueDispatchReducer,
            compose(applyMiddleware(thunkMiddleware))
        );
        return store;
    }
};
