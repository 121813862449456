import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import { BrandingTextValue } from "../../CustomizeBranding/Domain/BrandingTextValue";
import { Logo } from "./Logo";
import { Mobile } from "./Mobile";
import { ReportsNav } from "./ReportsNav";
import { SubMenu } from "./SubMenu";
import { UserInfo } from "./UserInfo";
import { NavbarLink } from "./types";
import { checkCurrentUrl } from "./utils";


type OrganizationHeaderProps = {
    isPermissionClick: boolean;
    isDistrictAdministrator: boolean;
    isAdministrator: boolean;
    isReadonly: boolean;
    hasEventTemplateFeature: boolean;
    hasInternalFormFeature: boolean;
    hasDownloadResponsesFeature: boolean;
    hasApiAccess: boolean;
    hasSafePay: boolean;
    hasSubscriptionsEnabled: boolean;
    homeUri: string;
    logoUri: string;
    myApprovalsUri: string;
    formsUri: string;
    depositReportUri: string;
    templatesUri: string;
    organizationTitle: string;
    editOrganizationUri: string;
    manageUsersUri: string;
    bankHookUri: string;
    brandingTextUri: string;
    responseExporterUri: string;
    apiUri: string;
    subscriptionManagementUri: string;
    dashboardUri: string;
    districtName: string;
    organizationName: string;
    userName: string;
    userRoleName: string;
    organizationLogoUri: string;
    email: string;
    editionName: string;
    brandingTextValues: BrandingTextValue[];
    logoutUri: string;
}

export const OrganizationHeader = (props: OrganizationHeaderProps) => {
    const location = useLocation();
    const currentUri = location.pathname;
    const currentHash = location.hash;
    const isActiveLink = checkCurrentUrl({ currentUri, currentHash });

    const orgBrandingText = props.brandingTextValues?.find((item) =>
        item.brandingTextItem.defaultValues['Default'] === "Organization"
    );

    const links: NavbarLink[] = [
        {
            label: props.isDistrictAdministrator || props.isPermissionClick ? "School Approvals" : "Approvals",
            url: props.myApprovalsUri,
            enabled: true,
        },
        {
            label: "Forms",
            url: props.formsUri,
            enabled: true,
        },
        {
            label: "Reports",
            component: <ReportsNav key="Reports" isActiveLink={isActiveLink} />,
            enabled: true,
        },
        {
            label: "Admin",
            enabled: props.isPermissionClick || props.isDistrictAdministrator || props.isAdministrator,
            children: [
                {
                    label: `${orgBrandingText ? orgBrandingText.value : 'Organization'} Templates`,
                    url: props.templatesUri,
                    enabled: props.hasEventTemplateFeature || props.hasInternalFormFeature,
                },
                {
                    label: "Edit Organization",
                    url: props.editOrganizationUri,
                    enabled: true,
                },
                {
                    label: "User Management",
                    url: props.manageUsersUri,
                    enabled: true,
                },
                {
                    label: `${props.hasSafePay ? "SafePay" : "Set Up"} Payment Collection`,
                    url: props.bankHookUri,
                    enabled: true,
                },
                {
                    label: "Customize Role Labels",
                    url: props.brandingTextUri,
                    enabled: true,
                },
                {
                    label: "Email Response Documents",
                    url: props.responseExporterUri,
                    enabled: props.hasDownloadResponsesFeature,
                },
                {
                    label: "API Access",
                    url: props.apiUri,
                    enabled: props.hasApiAccess,
                },
                {
                    label: "Subscription Management",
                    url: props.subscriptionManagementUri,
                    enabled: props.hasSubscriptionsEnabled,
                },
            ],
        },
    ];

    return (
        <nav className="organization-header">
            <ul
                id="nav-ul"
                className="layout-content"
            >
                <Mobile
                    logoUri={props.logoUri}
                    organizationLogoUri={props.organizationLogoUri}
                    organizationName={props.organizationName}
                    logoutUri={props.logoutUri}
                    links={links}
                />
                <li className="logo">
                    <Link className="nav-home" to={props.homeUri}>
                        <Logo
                            logoUri={props.logoUri}
                        />
                    </Link>
                </li>

                {
                    links.filter(({ enabled }) => enabled).map(link =>
                        link.children && link.children.length
                            ? (
                                <SubMenu key={link.label} label={link.label} links={link.children} />
                            )
                            : link.component
                                ? <React.Fragment key={link.label}>
                                    {link.component}
                                </React.Fragment>
                                : (
                                    <li key={link.label} className="menu">
                                        <span className="notification-wrapper">
                                            {link.url && link.url.charAt(0) === "/" ?
                                                <Link className={`nav-link ${isActiveLink(link.url) ? 'active' : ''}`} to={link.url}>{link.label}</Link>
                                                : <a
                                                    className={`nav-link ${isActiveLink(link.url) ? 'active' : ''}`}
                                                    href={link.url}
                                                >
                                                    {link.label}
                                                </a>
                                            }
                                            {
                                                link.showNotification ? (
                                                    <span
                                                        className="circle notification"
                                                        aria-label="There are pending approvals"
                                                        tabIndex={0}
                                                    ></span>
                                                ) : null
                                            }
                                        </span>
                                    </li>
                                )
                    )
                }

                <UserInfo
                    districtName={props.districtName}
                    organizationName={props.organizationName}
                    userName={props.userName}
                    userRoleName={props.userRoleName}
                    organizationLogoUri={props.organizationLogoUri}
                    showDistrictName={true}
                    email={props.email}
                    dashboardUri={props.dashboardUri}
                    isPermissionClick={props.isPermissionClick}
                    isDistrictAdministrator={props.isDistrictAdministrator}
                    editionName={props.editionName}
                />
            </ul>
        </nav>
    )
}
