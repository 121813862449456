import { RoutePaths } from '@src/Routes';
import { useSiteHeaderSelector } from '@src/customHooks/siteHeaderStore';
import { FullScreenLoading } from '@src/pages/LandingPages/FullScreenLoading';
import { isValidGUID } from '@src/utils/regex';
import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Navigate, Outlet, useLocation, useParams } from 'react-router-dom';
import jwt from '../../utils/jwt';
import { Dispatchers } from '../Dispatchers';
import { ErrorComponent } from '../molecules/Error/Error';
import { PrivateFooter } from '../molecules/PrivateFooter/PrivateFooter';
import { RunFirstLoad } from '../molecules/SiteHeader/Domain/Action/RunFirstLoad';
import { siteHeaderContext } from '../molecules/SiteHeader/Domain/Store';
import { AppPage } from './AppPage';

interface Props {
}


const dispatchers = {
    RunFirstLoad,
};

const ProtectedAppPageComponent: FC<Props & Dispatchers<typeof dispatchers>> = (props) => {
    const hasValidToken = jwt.hasValidToken();
    const userCountryCode = jwt.getCountry();
    const location = useLocation();

    const userIsLoaded = useSiteHeaderSelector((x) => !!x.user);
    const acceptedTermsDate = useSiteHeaderSelector((x) => x.user?.acceptedTermsDate);
    const error = useSiteHeaderSelector((x) => x.error);

    const activeProfile = useSiteHeaderSelector((x) => x.profiles)?.find(x => x.isSelected);
    const organization = useSiteHeaderSelector((x) => x.organization);
    const organizationName = useSiteHeaderSelector((x) => x.organizationName);

    const hasAcceptedTerms = !!acceptedTermsDate;

    const { RunFirstLoad } = props;
    const { shardCode, organizationId } = useParams();

    const shardCodeToUse = shardCode || userCountryCode || "int";
    const orgIdToUse = organizationId && isValidGUID(organizationId) ? organizationId : undefined;

    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        if (hasValidToken) {
            setIsLoading(true);
            RunFirstLoad(shardCodeToUse, orgIdToUse)
                .finally(() => finishLoading());
        }
    }, [RunFirstLoad, shardCodeToUse, orgIdToUse, hasValidToken]); // runs only once

    const finishLoading = async () => {
        setIsLoading(false);
        
        if (userIsLoaded && activeProfile && organization && organizationName) {
            try {
                const countryCodes = new Set([
                    'AT', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 
                    'DE', 'GR', 'HU', 'IE', 'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 
                    'PL', 'PT', 'RO', 'SK', 'SI', 'ES', 'SE', 'CH', 'GB', 'IS', 
                    'NO', 'LI'
                  ]);

                let profileId = activeProfile.id;

                // If organization country code is in the list of EU countries, then we need to randomize the ID.
                if (countryCodes.has(organization.countryCode.toUpperCase().trim())) {
                    profileId = Math.floor(Math.random() * (1000000 - 1) + 1).toString();
                }

                // Hash profile ID
                const hashedIdBuffer = await crypto.subtle.digest("SHA-256", new TextEncoder().encode(profileId));
                const hashArray = Array.from(new Uint8Array(hashedIdBuffer)); 
                const hashedId = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');

                // Initialize Gainsight.
                //@ts-expect-error - apstrinisc is Gainsight.
                aptrinsic("identify",
                    {
                        //User Fields
                        "id": `PC-${hashedId}`, // This can't actually be a user/profile id. It has to be unique and hashed. See desc in SMPC-12209.
                        "role": activeProfile.userRole, // The role of the user's profile.
                    },
                    {
                        //Account Fields
                        "id": `PC-${organization.id}`, // This should be the org id.
                        "name": organizationName, // Name of the org that the profile belongs to.
                    });
                console.log('Gainsight initialized');
            } catch (error) {
                console.error(error);
            }
        }
    };

    if (hasValidToken) {
        const currentPath = location.pathname.toLowerCase();
        const acceptTermsPath = RoutePaths.AcceptTerms.toLowerCase();

        if (error)
            return <>
                <div id="site-header">
                    <header></header>
                </div>
                <div id={"content"}>
                    <ErrorComponent error={error} />
                </div>
                <div id="private-footer">
                    <PrivateFooter />
                </div>
            </>;
        else if (!userIsLoaded || isLoading)
            return <FullScreenLoading />;
        else if (hasAcceptedTerms && currentPath === acceptTermsPath)
            return <Navigate to={RoutePaths.Landing} />;
        else if (!hasAcceptedTerms && currentPath === acceptTermsPath)
            return <Outlet />;
        else if (!hasAcceptedTerms && currentPath !== acceptTermsPath)
            return <Navigate to={RoutePaths.AcceptTerms} />;
        else
            return <AppPage>
                <Outlet />
            </AppPage>;

    } else {
        jwt.redirectToLogin();
        return <></>;
    }
};

export const ProtectedAppPage = connect(
    () => ({
    }),
    dispatchers, undefined, { context: siteHeaderContext }
)(ProtectedAppPageComponent);
