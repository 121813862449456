import * as React from "react";
import { connect } from "react-redux";
// TODO: import { UnseenReportRequests } from "../../Reports/Component/UnseenReportRequests";
import { UnseenReportRequests } from "@src/components/atoms/UnseenReportRequests";
import { Link } from "react-router-dom";
import { Dispatchers } from "../../../Dispatchers";
import { loadUnseenReportRequestCount } from "../Domain/Action/LoadUnseenReportRequestCount";
import { ReportsNavState } from "../Domain/ReportsNavState";
import { siteHeaderContext } from "../Domain/Store";

interface ComponentProps {
    reportManagerUri: string;
    numUnseenReportRequests: number;
    isActiveLink: (uri: string) => boolean;
}

interface State {
    loading: boolean
    loadComplete: boolean
}

const dispatchers = {
    loadUnseenReportRequestCount
};

type Props = ComponentProps & Dispatchers<typeof dispatchers>;


class ReportsNavComponent extends React.Component<Props, State> {

    public constructor(props: Props) {

        super(props);

        this.state = {
            loading: false,
            loadComplete: false
        }
    }

    public componentDidMount() {
        this.load();
    }

    private async load() {
        this.setState({
            loading: true
        }, () => this.props.loadUnseenReportRequestCount().then(
            () => this.setState({
                loading: false,
                loadComplete: true
            })));
    }


    public render() {
        const isActive = this.props.isActiveLink(this.props.reportManagerUri);

        const { reportManagerUri, numUnseenReportRequests } = this.props;
        return <li className="menu">
            <span>
                <Link
                    className={`nav-link nav-dashboard ${isActive ? 'active' : ''}`}
                    to={reportManagerUri}>
                    Reports
                </Link>
                {
                    numUnseenReportRequests > 0
                        ? <UnseenReportRequests numUnseenReportRequests={numUnseenReportRequests} />
                        : null
                }
            </span>
        </li>;
    }
}

export const ReportsNav = connect(
    (state: ReportsNavState) => ({
        reportManagerUri: state.uris.reportManager,
        numUnseenReportRequests: state.data?.numUnseenReportRequests,

    }),
    dispatchers, undefined, { context: siteHeaderContext }
)(ReportsNavComponent);
