import { Dispatchers } from "@src/components/Dispatchers";
import * as React from "react";
import { connect } from "react-redux";
import { BrandingTextValue } from "../../CustomizeBranding/Domain/BrandingTextValue";
import { getOrganizations } from "../Domain/Action/GetOrganizations";
import { ProfileListItem } from "../Domain/ProfileListItem";
import { SiteHeaderState, siteHeaderContext } from "../Domain/Store";
import { DistrictHeader } from "./DistrictHeader";
import { OrganizationHeader } from "./OrganizationHeader";
import { ProfileSelectionModal } from "./ProfileSelectionModal";
import { ReadOnlyBar } from "./ReadOnlyBar";
import { SuperOrgHeader } from "./SuperOrgHeader";

interface Props {
    isSuperOrg: boolean;
    isDistrict: boolean;
    isReadonly: boolean;
    isDistrictAdministrator: boolean;
    isAdministrator: boolean;
    isCanadianOrganization: boolean;
    hasProcessTemplatesFeature: boolean;
    hasDistrictFormsFeature: boolean;
    hasDownloadResponsesFeature: boolean;
    hasApiAccess: boolean;
    hasSafePay: boolean;
    hasEventTemplateFeature: boolean;
    hasInternalFormFeature: boolean;
    hasSubscriptionsEnabled: boolean;
    organizationTitle: string;
    isPermissionClick: boolean;
    districtName: string;
    organizationName: string;
    userName: string;
    userRoleName: string;
    email: string;
    profilesWithAccessToCurrentPage: ProfileListItem[];
    editionName: string;
    hasIntegrationManagementAccess: boolean;
    hasActiveDirectoryManagementAccess: boolean;
    uris: {
        home: string;
        logo: string;
        dashboard: string;
        myApprovals: string;
        processOverview: string;
        forms: string;
        templates: string;
        createOrganization: string;
        manageUsers: string;
        editOrganization: string;
        brandingText: string;
        gstReport: string;
        responseExporter: string;
        api: string;
        depositReport: string;
        bankHook: string;
        subscriptionManagement: string;
        organizationLogo: string;
        integrationManagement: string;
        rosterManagement: string;
        integratedDataManagement: string;
        logout: string;
        activeDirectoryManagement: string;
    };
    brandingTextValues: BrandingTextValue[];
}

const dispatchers = {
    getOrganizations,
};

const SiteHeaderComponent = (props: Props & Dispatchers<typeof dispatchers>) => {

    return props.uris &&
        <>
            <header id="hideaway-header">
                <div>
                    {!props.uris ? null : <>
                        {props.isSuperOrg
                            ? (
                                <SuperOrgHeader
                                    homeUri={props.uris.home}
                                    logoUri={props.uris.logo}
                                    dashboardUri={props.uris.dashboard}
                                    organizationName={props.organizationName}
                                    userName={props.userName}
                                    userRoleName={props.userRoleName}
                                    organizationLogoUri={props.uris.organizationLogo}
                                    email={props.email}
                                    logoutUri={props.uris.logout}
                                />
                            )

                            : props.isDistrict
                                ? (
                                    <DistrictHeader
                                        isPermissionClick={props.isPermissionClick}
                                        hasProcessTemplatesFeature={props.hasProcessTemplatesFeature}
                                        hasDistrictFormsFeature={props.hasDistrictFormsFeature}
                                        hasDownloadResponsesFeature={props.hasDownloadResponsesFeature}
                                        hasApiAccess={props.hasApiAccess}
                                        isDistrictAdministrator={props.isDistrictAdministrator}
                                        isCanadianOrganization={props.isCanadianOrganization}
                                        homeUri={props.uris.home}
                                        logoUri={props.uris.logo}
                                        myApprovalsUri={props.uris.myApprovals}
                                        processOverviewUri={props.uris.processOverview}
                                        formsUri={props.uris.forms}
                                        templatesUri={props.uris.templates}
                                        createOrganizationUri={props.uris.createOrganization}
                                        manageUsersUri={props.uris.manageUsers}
                                        editOrganizationUri={props.uris.editOrganization}
                                        brandingTextUri={props.uris.brandingText}
                                        gstReportUri={props.uris.gstReport}
                                        responseExporterUri={props.uris.responseExporter}
                                        apiUri={props.uris.api}
                                        dashboardUri={props.uris.dashboard}
                                        districtName={props.districtName}
                                        organizationName={props.organizationName}
                                        userName={props.userName}
                                        userRoleName={props.userRoleName}
                                        organizationLogoUri={props.uris.organizationLogo}
                                        email={props.email}
                                        editionName={props.editionName}
                                        hasIntegrationManagementAccess={props.hasIntegrationManagementAccess}
                                        integrationManagementUri={props.uris.integrationManagement}
                                        rosterManagementUri={props.uris.rosterManagement}
                                        integratedDataManagementUri={props.uris.integratedDataManagement}
                                        hasActiveDirectoryManagementAccess={props.hasActiveDirectoryManagementAccess}
                                        activeDirectoryManagementUri={props.uris.activeDirectoryManagement}
                                        logoutUri={props.uris.logout}
                                    />
                                )
                                : (
                                    <OrganizationHeader
                                        isPermissionClick={props.isPermissionClick}
                                        isDistrictAdministrator={props.isDistrictAdministrator}
                                        isAdministrator={props.isAdministrator}
                                        isReadonly={props.isReadonly}
                                        hasEventTemplateFeature={props.hasEventTemplateFeature}
                                        hasInternalFormFeature={props.hasInternalFormFeature}
                                        hasDownloadResponsesFeature={props.hasDownloadResponsesFeature}
                                        hasApiAccess={props.hasApiAccess}
                                        hasSafePay={props.hasSafePay}
                                        hasSubscriptionsEnabled={props.hasSubscriptionsEnabled}
                                        organizationTitle={props.organizationTitle}
                                        homeUri={props.uris.home}
                                        logoUri={props.uris.logo}
                                        myApprovalsUri={props.uris.myApprovals}
                                        formsUri={props.uris.forms}
                                        depositReportUri={props.uris.depositReport}
                                        templatesUri={props.uris.templates}
                                        editOrganizationUri={props.uris.editOrganization}
                                        manageUsersUri={props.uris.manageUsers}
                                        bankHookUri={props.uris.bankHook}
                                        brandingTextUri={props.uris.brandingText}
                                        responseExporterUri={props.uris.responseExporter}
                                        apiUri={props.uris.api}
                                        dashboardUri={props.uris.dashboard}
                                        subscriptionManagementUri={props.uris.subscriptionManagement}
                                        districtName={props.districtName}
                                        organizationName={props.organizationName}
                                        userName={props.userName}
                                        userRoleName={props.userRoleName}
                                        organizationLogoUri={props.uris.organizationLogo}
                                        email={props.email}
                                        editionName={props.editionName}
                                        brandingTextValues={props.brandingTextValues}
                                        logoutUri={props.uris.logout}
                                    />
                                )}
                    </>}
                </div>

                {props.profilesWithAccessToCurrentPage
                    && props.profilesWithAccessToCurrentPage.length > 1
                    ? (
                        <ProfileSelectionModal
                            profiles={props.profilesWithAccessToCurrentPage}
                        />
                    ) : null
                }
            </header>
            {props.isReadonly ? <ReadOnlyBar isPermissionClick={props.isPermissionClick} /> : null}
        </>;
};

export const SiteHeader = connect(
    (state: SiteHeaderState) => ({
        ...state,
        isReadonly: state.isReadonly
    }
    ),
    dispatchers, undefined, { context: siteHeaderContext }
)(SiteHeaderComponent);